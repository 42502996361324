<!-- Title -->
<div id="title-bar">
    <span class="col-md-2">
        <a data-testid="back-to-search-results" (click)="backToSearch()"> << Back to search results </a>
    </span>
    <span class="col-md-8">
        <h1>View Policy</h1>
    </span>
    <span class="col-md-2" hidden></span>
</div>

<!-- Loading Spinner -->
<div *ngIf="loading" class="row justify-content-center my-3">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div *ngIf="!loading && !error">
    <mat-card class="my-3 px-3">
        <div class="row">
            <div class="col-sm-4">
                <mat-card-header>
                    <mat-card-title>Policy Details</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table class="mat-table">
                        <tr>
                            <th class="mat-header-cell">Insured Name</th>
                            <td class="mat-cell" data-testid="InsuredName">{{ paClient.companyName }}</td>
                        </tr>
                        <tr>
                            <th class="mat-header-cell">Policy Number</th>
                            <td class="mat-cell" data-testid="policyNumber">
                                {{ clientPolicy.reference }}
                                <span *ngIf="clientPolicy.referenceRevision">
                                    Revision {{ clientPolicy.referenceRevision }}
                                </span>
                            </td>
                        </tr>
                        <tr *ngIf="showInceptionDate">
                            <th class="mat-header-cell">Inception Date</th>
                            <td class="mat-cell" data-testid="inceptionDate">
                                <pa-format-date [dateConfig]="policyDetails.inceptionDate"></pa-format-date>
                            </td>
                        </tr>
                        <tr *ngIf="!showInceptionDate">
                            <th class="mat-header-cell">Renewal Date</th>
                            <td class="mat-cell" data-testid="renewalDate">
                                <pa-format-date [dateConfig]="policyDetails.renewalDate"></pa-format-date>
                            </td>
                        </tr>
                        <tr *ngIf="clientPolicy.amendedDate">
                            <th class="mat-header-cell">Amendment effective date</th>
                            <td class="mat-cell" data-testid="amendedDate">
                                <pa-format-date [dateConfig]="policyDetails.amendedDate"></pa-format-date>
                            </td>
                        </tr>
                        <tr>
                            <th class="mat-header-cell">Expiry Date</th>
                            <td class="mat-cell" data-testid="expiryDate">
                                <pa-format-date [dateConfig]="policyDetails.expiryDate"></pa-format-date>
                            </td>
                        </tr>
                        <tr *ngIf="clientPolicy.interestedParties.length">
                            <th class="mat-header-cell">Policy-Wide Interested Parties</th>
                            <td class="mat-cell">
                                <tr *ngFor="let ip of clientPolicy.interestedParties">
                                    {{
                                        ip.name
                                    }}
                                </tr>
                            </td>
                        </tr>
                    </table>
                </mat-card-content>
            </div>
            <div class="col-sm-4">
                <mat-card-header>
                    <mat-card-title>Policy Documentation Links</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table>
                        <li *ngFor="let attachment of attachments">
                            <a [href]="attachment.url" target="_blank"> {{ attachment.fileName }}</a>
                        </li>
                    </table>
                </mat-card-content>
            </div>
            <div class="col-sm-4">
                <mat-card-header>
                    <mat-card-title>Customer Service Actions</mat-card-title>
                </mat-card-header>
                <div *ngIf="loadingAction" class="row m-5">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div *ngIf="!loadingAction">
                    <mat-card-content>
                        <a
                            *ngIf="!hideRegenerateLink"
                            href="#"
                            (click)="openRegenerateAcceptModal()"
                            data-testid="regenerateDocuments"
                        >
                            Regenerate Policy Documentation ->
                        </a>
                    </mat-card-content>
                    <mat-card-content>
                        <a
                            *ngIf="!hideResendLink"
                            href="#"
                            (click)="openResendAcceptModal()"
                            data-testid="resendNotification"
                        >
                            Resend Policy Email ->
                        </a>
                    </mat-card-content>
                    <mat-card-content>
                        <a
                            *ngIf="!hideReassignBrokerLink"
                            href="#"
                            (click)="openReassignBrokerModal()"
                            data-testid="reassignBroker"
                        >
                            Reassign Broker ->
                        </a>
                    </mat-card-content>
                    <mat-card-content>
                        <a
                            href="#"
                            *ngIf="!hideProcessCancelLink"
                            (click)="openProcessCancelModal()"
                            data-testid="processCancellation"
                        >
                            Process Cancellation ->
                        </a>
                    </mat-card-content>
                    <mat-card-content>
                        <a
                            *ngIf="showAmendPolicyLink"
                            [attr.href]="amendPolicyLink"
                            target="_blank"
                            data-testid="amendPolicy"
                        >
                            Amend Policy ->
                        </a>
                    </mat-card-content>
                </div>
            </div>
        </div>
    </mat-card>
</div>

<!-- Error -->
<div *ngIf="!loading && error">
    <div class="row justify-content-center my-3">
        <p class="h5">{{ errorMessage }}</p>
    </div>
</div>

<!-- Service action dialogs -->
<confirm-dialog
    #confirmDialog
    title="{{ cancelStatus ? 'Process Cancellation' : 'Please Confirm!' }}"
    confirmBtnLabel="{{ cancelStatus ? 'Process Cancellation' : 'Accept' }}"
    (confirmClicked)="acceptServiceAction(serviceActionEvent)"
    (cancellationDate)="cancellationEvent($event)"
></confirm-dialog>
<confirm-dialog
    #responseDialog
    title="{{ undefined }}"
    confirmBtnLabel="{{ undefined }}"
    (closeClicked)="closeClicked()"
></confirm-dialog>

<!-- Custom dialogs -->
<confirm-dialog #reassignBrokerDialog confirmBtnLabel="Accept" (confirmClicked)="confirmReassignBroker()">
    <div class="row justify-content-center m-3" *ngIf="!loadingDetails">
        <h1 data-testid="organisationName">{{ organisation?.name }}</h1>
        <mat-select
            [(value)]="selectedOriginatorCode"
            class="border-bottom border-dark"
            data-testid="selectReassignOriginator"
        >
            <mat-option
                *ngFor="let orgOriginator of organisationOriginators"
                [value]="orgOriginator.code"
                [disabled]="orgOriginator.code === originator.code"
                [attr.data-testid]="'originator-' + orgOriginator.code"
            >
                {{ orgOriginator.individual }} <span *ngIf="orgOriginator.code === originator.code">*</span>
            </mat-option>
        </mat-select>
    </div>
    <div *ngIf="loadingDetails" class="row justify-content-center my-3">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</confirm-dialog>
