import _ from 'lodash';
import { ColDef, GridOptions, ValueFormatterParams } from 'ag-grid-community';
import { EditCellComponent } from '../../components/form/edit-cell/edit-cell.component';
import { AssetModel } from '../edit-asset/types';
import { AssetType, assetTypes } from '@pa/references/paul-precision';
import { DiscountType, TransactionType } from '@pa/references/idf';
import { QuotePriceInput } from '@pa/sdk/idf';
import { UiConfig } from '@pa/sdk/idf';

export enum AgGridRowEvents {
    Edit = 'edit',
    Delete = 'delete',
}

export interface AgGridRowEventVars {
    action?: AgGridRowEvents;
    assetType?: AssetType;
}

export const isPinnedBottom = (params: any) => params?.node?.rowPinned === 'bottom';

export const getAssetGridColumns = (
    rowEventVars: AgGridRowEventVars,
    validateAssetQuote: (assetQuote: AssetModel, assetType: AssetType) => boolean,
    currencyFormatter: (params: ValueFormatterParams) => string,
    assetType: AssetType,
    transactionType: TransactionType,
    uiConfig?: UiConfig
): GridOptions => {
    const isAircraftOrUav = assetType === assetTypes.aircraft || assetType === assetTypes.uav;
    let columnDefs: ColDef[] = [
        {
            headerClass: 'warning-column',
            cellClass: 'warning-column',
            suppressAutoSize: true,
            suppressSizeToFit: true,
            tooltipValueGetter: (params) => {
                const missingPremiums = params.data.missingPremiums;
                if (missingPremiums) {
                    const premiumsRequired = Object.keys(missingPremiums);

                    let premiumsMissing = [];
                    premiumsRequired.forEach((premium) => {
                        if (missingPremiums[premium]) {
                            premiumsMissing.push(premium);
                        }
                    });
                    return `Missing ${premiumsMissing.join(', ')} premiums`;
                } else {
                    return 'Please enter a premium';
                }
            },
            cellRenderer: (params: any): string => {
                const isValid = validateAssetQuote(params.data, assetType);
                return !isValid ? '<i class="fal fa-bell-on"></i>' : '';
            },
        },
        {
            headerName: 'Amend Type',
            field: 'amendType',
            hide: [TransactionType.newBusiness, TransactionType.renewal].includes(transactionType),
        },
        {
            headerName: 'Registration',
            field: 'serialNumber',
        },
        {
            headerName: 'Make',
            field: 'manufacturer',
            hide: !isAircraftOrUav,
        },
        {
            headerName: 'Model',
            field: assetType === assetTypes.uav ? 'uavModel' : 'model',
            hide: !isAircraftOrUav,
        },
        {
            headerName: 'MTOM',
            field: 'mtom',
            hide: !(assetType === AssetType.uav),
            valueFormatter: (params: ValueFormatterParams) => {
                return params.data.mtom ? params.data.mtom + ' kg' : undefined;
            },
        },
        {
            headerName: 'Description',
            field: 'description',
            hide: isAircraftOrUav,
        },
        {
            headerName: 'Payload Type',
            field: 'payloadType',
            hide: assetType !== assetTypes.uavPayload,
            valueFormatter: ({ value }) => uiConfig?.payloadTypes?.find((p) => p.type === value)?.display ?? value,
        },
        {
            headerName: 'Equipment Type',
            field: 'equipmentType',
            hide: assetType !== assetTypes.uavEquipment || !uiConfig?.equipmentTypes?.length,
            valueFormatter: ({ value }) => uiConfig?.equipmentTypes?.find((eq) => eq.type === value)?.display ?? value,
        },

        {
            headerName: 'Premium',
            valueGetter: (params) => {
                if (isPinnedBottom(params)) {
                    return params.data.totalPremium;
                }
                const discountRate = _.round(
                    params.data.discountPriceYear
                        .filter((dpy) => dpy.type === DiscountType.policyRemainingProRata)
                        .reduce((acc, cur) => acc + (cur.rate ?? 0), 0),
                    4
                );

                const total = _.sum(
                    (params.data.priceYearAdjusted as QuotePriceInput[]).map(
                        (qp) => qp.hull ?? qp.liability ?? qp.standard ?? 0
                    )
                );

                return _.round((1 - discountRate) * total, 2);
            },
            valueFormatter: (params) => {
                if (
                    params.data.missingPremiums &&
                    Object.values(params.data.missingPremiums).some((missing) => missing)
                ) {
                    return 'NA';
                }
                return currencyFormatter(params);
            },
            cellClass: (params) =>
                isPinnedBottom(params)
                    ? `data-testid-total-${assetType}-premium`
                    : `data-testid-${params.data.serialNumber}-premium`,
        },
        {
            headerName: '',
            cellRendererFramework: EditCellComponent,
            cellRendererParams: {
                onEdit: () => {
                    rowEventVars.action = AgGridRowEvents.Edit;
                    rowEventVars.assetType = assetType;
                },
                showLabelsAtSize: 'lg',
            },
        },
    ];
    return {
        defaultColDef: {
            resizable: true,
            width: 100,
        },
        getRowStyle: (params) => (isPinnedBottom(params) ? { 'font-weight': 'bold' } : {}),
        columnDefs,
    };
};
