import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormlyFieldConfig, FormlyForm } from '@ngx-formly/core';
import { GridApi, RowClickedEvent, ValueFormatterParams } from 'ag-grid-community';
import { Auth } from 'aws-amplify';
import _, { cloneDeep, isEqual } from 'lodash';

import { validateAssetQuote } from '@pa/lib-validators/assetQuotes';
import { getAssetEndorsements } from '@pa/lib-validators';
import {
    CurrencyCode,
    DiscountType,
    EndorsementAppliesTo,
    EndorsementCategory,
    formatCurrencyAmountByFormatter,
    InterestedPartyNotedOn,
    PriceType,
    QuoteStatus,
    TransactionType,
    interestedPartyTypeMapping,
    transactionTypeMapping,
    referralTypeMapping,
    ClientReferralType,
    OriginatorType,
    UnderwritingActions,
    productTypes,
    StatusFlagType,
    ReferralType,
    FeatureFlags,
} from '@pa/references/idf';
import { AssetType } from '@pa/references/paul-precision';
import {
    AssetPolicy,
    ClientPolicy,
    ConfigurationDocuments,
    Endorsement,
    InsurerAssetTemplate,
    UnderwritingConfig,
    UnderwritingConfigRequest,
} from '@pa/sdk/cmf';
import {
    AircraftProposal,
    AssetProposal,
    AssetQuote,
    Behaviour,
    ClientProposal,
    ProposalEndorsement,
    ClientQuote,
    FormConfig,
    Originator,
    ProposalInterestedParty,
    UavProposal,
    UiConfig,
    QuoteEndorsement,
} from '@pa/sdk/idf';
import { AssetTemplate, User, Asset, Pilot } from '@pa/sdk/rmf';
import { BehaviourService, FormConfigService } from '@pa/lib-spa';

import { AuthService } from '../core/auth/auth.service';
import { QuoteService } from '../services/quote.service';
import { NotificationService } from '../common/notification.service';
import { ConfirmDialogComponent } from '../components/dialogs/confirm-dialog.component';
import { getCurrencyFormatter } from '../core/utils/value.formatters';
import { PolicyService } from '../services/policy.service';
import { AgGridRowEvents, AgGridRowEventVars, getAssetGridColumns } from './utils/ag-grid';
import { declineModal } from './decline.modal';
import { EditAssetModalComponent } from './edit-asset/asset.modal';
import { AssetModel } from './edit-asset/types';
import { getEndorsementGridOptions } from './endorsement';
import { filterZeroPrices } from './utils/endorsements';
import { PolicyReferral, ProposalReferrals, StatusFlagDisplay, StatusFlagSection } from './types';
import { policyEndorsementsValidator } from './utils/validators';
import { concatMap, map, switchMap, take } from 'rxjs/operators';
import { forkJoin, from, Observable, of } from 'rxjs';
import { SdkService } from '../services/sdk.service';
import { feesGridOptions } from './utils/fees';
import { formatDate } from '../utils/date';
import { environment } from '../../environments/environment';
import { CustomerActionsComponents, CustomerActionsService } from '../services/customer-actions.service';
import { FormatDateConfig } from '@pa/lib-spa/types';

export enum AssetCheck {
    hull = 'hull',
    tpl = 'tpl',
    csl = 'csl',
    pll = 'pll',
    endorsements = 'endorsements',
}

@Component({
    selector: 'underwriting',
    templateUrl: './underwriting.component.html',
    styleUrls: ['underwriting.component.scss'],
})
export class UnderwritingComponent implements OnInit {
    @ViewChild('formlyForm') formlyForm?: FormlyForm;
    @ViewChild('editAssetModal') editAssetModal: EditAssetModalComponent;
    @ViewChild('confirmDialog') confirmDialog: ConfirmDialogComponent;

    private loginPortalURI = environment.apiBaseUrls.loginPortal;

    constructor(
        private router: ActivatedRoute,
        private authService: AuthService,
        private behaviourService: BehaviourService,
        private quoteService: QuoteService,
        private policyService: PolicyService,
        private notificationService: NotificationService,
        private changeDetectorRef: ChangeDetectorRef,
        private _sdk: SdkService,
        private _formConfigService: FormConfigService,
        private customerActionsService: CustomerActionsService
    ) {
        this.referrals = {
            assetReferrals: [],
            policyReferrals: [],
        };
    }

    agGridRowEventVars: AgGridRowEventVars = {};
    behaviour: Behaviour;
    behaviourId: string = '';
    broker: User;

    clientProposal: ClientProposal;
    enabledFeatureFlags: FeatureFlags[];
    showEditProposalLink: boolean = true; //false
    editProposalLink: string = '';
    loadingAction: boolean = true;

    brokerShow = false;
    clientPolicy?: ClientPolicy;
    currencyFormatter: Intl.NumberFormat = getCurrencyFormatter(CurrencyCode.AUD);
    discountRate: number = 0;
    existingAssets: Asset[] | undefined;
    existingPilots: Pilot[] | undefined;
    loadingProposal = true;
    transactionType: string;
    quoteStatus: string;
    referralReasons: string[];
    referrals: ProposalReferrals;
    notes: string[] = [];
    isNoteDisabled: boolean = true;
    EditSave: string = 'Edit';

    endorsements: Endorsement[] = [];
    fields: FormlyFieldConfig[] = [];

    form: any = new FormControl();
    insurerAssetTemplates: InsurerAssetTemplate[] = [];
    model: any = {};
    processing: boolean = false;
    proposal: any = {};
    proposalId: string = '';
    action?: UnderwritingActions;
    showUnderwritingButton = false;
    uForm: FormGroup = new FormGroup({});
    insurerUnderwritingConfig: UnderwritingConfig;
    configDocsWithoutVersions: ConfigurationDocuments;
    originatorType: string;
    isHybridOriginator: boolean;
    uiConfig?: UiConfig;
    formConfigs: FormConfig[];
    assetTemplates: AssetTemplate[];
    statusFlags: StatusFlagSection = {
        display: false,
    };
    datesConfig: { [date: string]: FormatDateConfig } = {};

    public get isValidToAccept(): boolean {
        return this.formlyForm.form.valid && !this.processing;
    }

    ngOnInit() {
        this.router.queryParams.subscribe((params) => {
            this.proposalId = params.proposalId;
            this.behaviourId = params.behaviourId;
            this.quoteService
                .getBehaviour(this.behaviourId)
                .toPromise()
                .then((b) => {
                    this.behaviourService.set(b);
                    this.behaviour = b;
                });
        });
        this._formConfigService.formConfig.subscribe((formConfigs) => {
            this.formConfigs = formConfigs;
        });

        this.authService.userSignedInSubject.subscribe((hasSigned) => {
            if (hasSigned && this.proposalId && this.behaviourId) {
                this.loadingProposal = true;
                this.processing = true;

                this.getQuote().subscribe({
                    next: () => {
                        this.loadingProposal = false;
                        this.loadingAction = true;
                        this.processing = false;

                        this.behaviourService.behaviour.subscribe((val) => {
                            this.uiConfig = val.uiConfig;
                        });

                        // NOTE: Form only loads after the data fetch, if performance is an issue then we may need to add
                        // a spinner or other indicator here.
                        this.fields = this._formlyFieldConfig(this.clientPolicy, this.uiConfig);
                        this.checkShowUnderwritingButton();
                        this.changeDetectorRef.detectChanges();

                        Object.values(AssetType).forEach((assetType: AssetType) => {
                            const agGridField = this.getAssetGridField(assetType);
                            const gridApi = agGridField?.templateOptions?.gridOptions?.api;
                            gridApi?.refreshCells({ force: true });
                            this.updateAssetTypeTotalPremium(gridApi, assetType);
                        });

                        this.customerActionsService
                            .getCustomerServiceActionLinks(
                                CustomerActionsComponents.underwriting,
                                this.proposalId,
                                this.behaviourId,
                                this.clientProposal,
                                this.clientProposal.clientQuote,
                                this.clientPolicy?.reference
                            )
                            .subscribe((res) => {
                                this.loadingAction = false;
                                this.showEditProposalLink = res.showEditProposal;
                            });
                    },
                    error: (err) => {
                        this.loadingProposal = false;
                        console.error(err);
                    },
                });

                this.editProposalLink = `${this.loginPortalURI}/pp/?proposalId=${this.proposalId}&behaviour=${this.behaviourId}&withSession=true`;
            }
        });
    }

    private _totalPolicyEndorsementPremiumRow() {
        const policyEndTypes: PriceType[] = (this.model.policyEndorsements as Endorsement[]).map((e) => e.type);
        const totalPremium = _.sum(
            (this.model.clientQuote as ClientQuote).priceYearAdjusted
                .filter((pya) => policyEndTypes.includes(pya.type))
                .map((pya) => pya.liability || pya.standard)
        );

        return totalPremium
            ? [
                  {
                      title: 'Total Policy Wide Endorsement Premium',
                      totalPremium,
                      totalProRata: _.round(totalPremium * (1 - this.discountRate), 2),
                  },
              ]
            : [];
    }

    private _formlyFieldConfig(clientPolicy?: ClientPolicy, uiConfig?: UiConfig): FormlyFieldConfig[] {
        return [
            {
                key: 'clientQuote.fees',
                type: 'ag-grid',
                hideExpression: (model) => !model?.length,
                defaultValue: [],
                templateOptions: {
                    wrapperTitle: 'Fees',
                    gridOptions: feesGridOptions(this.currencyValueFormatter),
                },
                wrappers: ['card'],
            },
            {
                key: 'policyEndorsements',
                type: 'ag-grid',
                defaultValue: [],
                templateOptions: {
                    wrapperTitle: 'Policy Wide Endorsements',
                    gridOptions: {
                        ...getEndorsementGridOptions(
                            this.model.transactionType,
                            this.currencyValueFormatter,
                            this.model,
                            clientPolicy
                        ),
                        onRowDataUpdated: (params) => {
                            params.api.setPinnedBottomRowData(this._totalPolicyEndorsementPremiumRow());
                        },
                        pinnedBottomRowData: this._totalPolicyEndorsementPremiumRow(),
                    },
                },
                wrappers: ['card'],
                validators: {
                    validation: [policyEndorsementsValidator(this.model)],
                },
                className: 'policyEndorsements',
            },
            {
                key: 'clientQuote.aircraftQuotes',
                type: 'ag-grid',
                defaultValue: [],
                hideExpression: (model) => !model.length,
                templateOptions: {
                    wrapperTitle: 'Aircraft Table',
                    gridOptions: {
                        ...getAssetGridColumns(
                            this.agGridRowEventVars,
                            this.validateAssetQuote,
                            this.currencyValueFormatter,
                            AssetType.aircraft,
                            this.model.transactionType
                        ),
                        onRowClicked: (event: RowClickedEvent) => {
                            if (this.agGridRowEventVars.action === AgGridRowEvents.Edit) {
                                this.editAsset(event);
                            }
                            delete this.agGridRowEventVars.action;
                        },
                        singleClickEdit: true,
                        animateRows: true,
                    },
                },
                wrappers: ['card'],
                validators: {
                    validation: [
                        (control: AbstractControl): any => {
                            const invalid = control.value?.some((aq) => !this.validateAssetQuote(aq));
                            return invalid ? { 'clientQuote.aircraftQuotes': true } : null;
                        },
                    ],
                },
            },
            {
                key: 'clientQuote.uavQuotes',
                type: 'ag-grid',
                defaultValue: [],
                hideExpression: (model) => !model.length,
                templateOptions: {
                    wrapperTitle: 'Drone Table',
                    gridOptions: {
                        ...getAssetGridColumns(
                            this.agGridRowEventVars,
                            this.validateAssetQuote,
                            this.currencyValueFormatter,
                            AssetType.uav,
                            this.model.transactionType
                        ),
                        onRowClicked: (event: RowClickedEvent) => {
                            // "(event.event as any).offsetX" is to fix an issue where
                            // an empty modal will be opened when triggering editing of policy wide endorsement premium
                            // Jira ticket: PP-1676
                            if (
                                this.agGridRowEventVars.action === AgGridRowEvents.Edit &&
                                (event.event as any).offsetX
                            ) {
                                this.editAsset(event);
                            }
                            delete this.agGridRowEventVars.action;
                        },
                        singleClickEdit: true,
                        animateRows: true,
                    },
                },
                wrappers: ['card'],
                validators: {
                    validation: [
                        (control: AbstractControl): any => {
                            const invalid = control.value?.some((aq) => !this.validateAssetQuote(aq));
                            return invalid ? { 'clientQuote.uavQuotes': true } : null;
                        },
                    ],
                },
            },
            {
                key: 'clientQuote.payloadQuotes',
                type: 'ag-grid',
                defaultValue: [],
                hideExpression: (model) => !model.length,
                templateOptions: {
                    wrapperTitle: 'Payloads Table',
                    gridOptions: {
                        ...getAssetGridColumns(
                            this.agGridRowEventVars,
                            this.validateAssetQuote,
                            this.currencyValueFormatter,
                            AssetType.uavPayload,
                            this.model.transactionType,
                            uiConfig
                        ),
                        onRowClicked: (event: RowClickedEvent) => {
                            if (this.agGridRowEventVars.action === AgGridRowEvents.Edit) {
                                this.editAsset(event);
                            }
                            delete this.agGridRowEventVars.action;
                        },
                        singleClickEdit: true,
                        animateRows: true,
                    },
                },
                wrappers: ['card'],
                validators: {
                    validation: [
                        (control: AbstractControl): any => {
                            const invalid = control.value?.some((aq) => !this.validateAssetQuote(aq));
                            return invalid ? { 'clientQuote.payloadQuotes': true } : null;
                        },
                    ],
                },
            },
            {
                key: 'clientQuote.equipmentQuotes',
                type: 'ag-grid',
                defaultValue: [],
                hideExpression: (model) => !model.length,
                templateOptions: {
                    wrapperTitle: 'Ground Equipment Table',
                    gridOptions: {
                        ...getAssetGridColumns(
                            this.agGridRowEventVars,
                            this.validateAssetQuote,
                            this.currencyValueFormatter,
                            AssetType.uavEquipment,
                            this.model.transactionType,
                            uiConfig
                        ),
                        onRowClicked: (event: RowClickedEvent) => {
                            if (this.agGridRowEventVars.action === AgGridRowEvents.Edit) {
                                this.editAsset(event);
                            }
                            delete this.agGridRowEventVars.action;
                        },
                        singleClickEdit: true,
                        animateRows: true,
                    },
                },
                wrappers: ['card'],
                validators: {
                    validation: [
                        (control: AbstractControl): any => {
                            const invalid = control.value?.some((aq) => !this.validateAssetQuote(aq));
                            return invalid ? { 'clientQuote.equipmentQuotes': true } : null;
                        },
                    ],
                },
            },
            {
                templateOptions: {
                    wrapperTitle: 'Subjectivities',
                },
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-11',
                        key: 'notes',
                        type: 'textarea',
                        templateOptions: {
                            wrapperLabel: 'Policy/quote notes',
                            maxLength: 5000,
                            rows: 2,
                        },
                    },
                    {
                        className: 'col-1',
                        type: 'button',
                        templateOptions: {
                            text: 'Add',
                            onClick: () => {
                                if (!!this.model.notes) {
                                    this.notes.push(this.model.notes);
                                    this.model.notes = '';
                                }
                                this.uForm.get('notes').setValue('');
                            },
                        },
                    },
                ],
                wrappers: ['card'],
            },
            declineModal(() => this.decline()),
        ];
    }

    getQuote() {
        return this.quoteService.getProposal(this.proposalId, this.behaviourId).pipe(
            concatMap((proposal) => {
                this.clientProposal = proposal;
                this.currencyFormatter = getCurrencyFormatter(proposal.currency);
                this.originatorType = (proposal.originator as Originator).type;
                this.brokerShow = (proposal.originator as Originator).type === OriginatorType.intermediary;
                this.isHybridOriginator =
                    (proposal.originator as Originator).type === OriginatorType.intermediaryHybrid ||
                    (proposal.originator as Originator).type === OriginatorType.insuredPolicyManager;
                let intermediaryUser$: Observable<User | undefined> = of(undefined);
                if (this.brokerShow || this.isHybridOriginator) {
                    intermediaryUser$ = this.quoteService.getUser(
                        this.behaviourId,
                        (proposal.originator as Originator)?.user as string
                    );
                }

                const isRetrievingPolicy =
                    [TransactionType.amendment, TransactionType.cancellation, TransactionType.renewal].includes(
                        proposal.transactionType
                    ) && proposal.clientPolicy;

                let clientPolicy$: Observable<ClientPolicy | undefined> = of(undefined);
                let assetPolicies$: Observable<AssetPolicy[] | undefined> = of(undefined);
                if (isRetrievingPolicy) {
                    clientPolicy$ = this.policyService.getPolicy(proposal.clientPolicy ?? '', this.behaviourId);
                    assetPolicies$ = this.policyService.getAssetPolicies(proposal.clientPolicy ?? '', this.behaviourId);
                }

                if (proposal.clientQuote?.discountPriceYear?.length) {
                    this.discountRate = _.round(
                        proposal.clientQuote.discountPriceYear
                            .filter((dpy) => dpy.type === DiscountType.policyRemainingProRata)
                            .reduce((acc, cur) => acc + (cur.rate ?? 0), 0),
                        4
                    );
                }

                const assetTemplateIds = [
                    ...((proposal.aircraft ?? []) as AircraftProposal[]),
                    ...((proposal.uavs ?? []) as UavProposal[]),
                ].map((a) => a.assetTemplate);

                return forkJoin([
                    of(proposal),
                    intermediaryUser$,
                    clientPolicy$,
                    assetPolicies$,
                    from(
                        this._sdk.rmf.AssetTemplates.get(this.behaviourId, {
                            _id: assetTemplateIds,
                            type: proposal.product === productTypes.aviation ? AssetType.aircraft : AssetType.uav,
                        })
                    ),
                ]);
            }),
            concatMap((res) => {
                const [proposal, intermediaryUser, clientPolicy, assetPolicies, assetTemplates] = res;

                this.assetTemplates = assetTemplates;
                if (clientPolicy && !clientPolicy.assetPolicies?.length) {
                    clientPolicy.assetPolicies = assetPolicies;
                }

                const assetQuotes = proposal.clientQuote.assetQuotes as AssetQuote[];
                const proposedAssets = [
                    ...((proposal.aircraft as AircraftProposal[]) ?? []),
                    ...((proposal.uavs as UavProposal[]) ?? []),
                ];

                const allProposedEndorsements = [
                    ...(proposal.clientQuote?.endorsements ?? []),
                    ...(assetQuotes?.flatMap((aq) => aq.endorsements ?? []) ?? []),
                    ...(assetPolicies ?? []).flatMap((ap) => ap.endorsements ?? []),
                ];

                const underwritingRequest: UnderwritingConfigRequest = {
                    behaviour: proposal.behaviour as string,
                    transactionType: proposal.transactionType,
                    clientPolicy: clientPolicy?._id,
                    defaultUnderwritingConfigVersions: proposal.clientQuote.underwritingConfigVersions,
                    endorsements: allProposedEndorsements.map((e) => {
                        return {
                            type: e.type,
                            underwritingConfigVersions: e.underwritingConfigVersions,
                        };
                    }),
                    lockRequestVersions: true,
                };

                const dynamicAssetTypes: readonly AssetType[] = [AssetType.aircraft, AssetType.uav];
                const dynamicAssetQuotes = assetQuotes?.filter((aq) => dynamicAssetTypes.includes(aq.assetType));

                if (dynamicAssetQuotes?.length) {
                    underwritingRequest.assets = dynamicAssetQuotes.map((aq) => {
                        const assetProposal = aq.assetProposal as AssetProposal;
                        const assetPolicy = assetPolicies?.find((ap) => ap.assetQuote === aq._id);
                        return {
                            assetTemplate: assetProposal.assetTemplate,
                            id: assetProposal.serialNumber,
                            assetPolicy: assetPolicy?._id,
                            underwritingConfigVersions: aq.underwritingConfigVersions,
                        };
                    });
                } else {
                    underwritingRequest.assets = proposedAssets.map((pa) => {
                        const assetPolicy = assetPolicies.find((ap) => ap._id === pa.assetPolicy);
                        return {
                            assetTemplate: pa.assetTemplate,
                            id: pa.serialNumber,
                            assetPolicy: pa?.assetPolicy,
                            underwritingConfigVersions: assetPolicy.underwritingConfigVersions,
                        };
                    });
                }

                return forkJoin([
                    of(proposal),
                    from(Auth.currentSession()).pipe(
                        switchMap((userSession) => {
                            this._sdk.idf.accessToken = userSession.getAccessToken().getJwtToken();
                            return from(this._sdk.idf.InsurerUnderwritingConfigs.get(underwritingRequest)).pipe(
                                map((res) => {
                                    this.insurerUnderwritingConfig = res;
                                    return res;
                                })
                            );
                        })
                    ),
                    of(intermediaryUser),
                    of(clientPolicy),
                ]);
            }),
            concatMap((res) => {
                let proposal: ClientProposal;
                [proposal, this.insurerUnderwritingConfig, this.broker, this.clientPolicy] = res;
                const { configurationDocuments, defaultUnderwritingConfigVersions } = this.insurerUnderwritingConfig;

                this.configDocsWithoutVersions = configurationDocuments.find(
                    (doc) => (doc.underwritingConfigVersions?.length ?? 0) === 0
                );

                const defaultConfigurationDocument =
                    configurationDocuments.find((doc) =>
                        isEqual(doc.underwritingConfigVersions, defaultUnderwritingConfigVersions)
                    ) ?? this.configDocsWithoutVersions;

                if (!defaultConfigurationDocument) {
                    throw new Error(
                        [
                            `Unable to match default underwritingConfigVersions with the version in `,
                            `configurationDocument ${JSON.stringify(
                                configurationDocuments.map((doc) => doc.underwritingConfigVersions)
                            )}`,
                        ].join(' ')
                    );
                }
                this.endorsements = defaultConfigurationDocument.insurerPrice.endorsements;
                this.insurerAssetTemplates = defaultConfigurationDocument.insurerAssetTemplates;

                if (this.insurerUnderwritingConfig.transactionType !== TransactionType.cancellation) {
                    this._mapEndorsements();
                    this._mapInsurerAssetTemplates();
                }

                const model = this.mapProposal(proposal, this.endorsements, this.discountRate);
                this.model = model;
                this.transactionType = model.transactionType ? transactionTypeMapping.get(model.transactionType) : '';
                this.quoteStatus = model.clientQuote?.status ? _.capitalize(model.clientQuote?.status) : '';

                if (this.clientPolicy) {
                    return from(Auth.currentSession()).pipe(
                        concatMap((userSession) => {
                            this._sdk.rmf.accessToken = userSession.getAccessToken().getJwtToken();
                            this._sdk.cmf.accessToken = userSession.getAccessToken().getJwtToken();

                            let pilots$: Observable<Pilot[] | undefined> = of(undefined);
                            if (this.clientPolicy.pilots?.length) {
                                pilots$ = from(
                                    this._sdk.rmf.Pilots.get(this.behaviourId, {
                                        ids: this.clientPolicy.pilots,
                                        paClient: this.clientPolicy.paClient,
                                    })
                                );
                            }

                            return forkJoin([
                                from(
                                    this._sdk.rmf.Assets.get(this.behaviourId, {
                                        ids: this.clientPolicy.assetPolicies.map((ap) => ap.appliesTo.asset),
                                    })
                                ),
                                pilots$,
                            ]);
                        })
                    );
                }

                return of(undefined);
            }),
            map((res) => {
                if (res) {
                    [this.existingAssets, this.existingPilots] = res;
                }
            })
        );
    }

    mapProposal(proposal: any, endorsements: Endorsement[], discountRate: number) {
        const mappedProposal = {
            ...proposal,
        };

        if (proposal.clientQuote.additionalNotes[0]) {
            // mappedProposal.notes = proposal.clientQuote.additionalNotes[0];
            this.notes = proposal.clientQuote.additionalNotes;
        }

        this.mapClientQuotes(mappedProposal);
        this.mapPolicyEndorsements(endorsements, mappedProposal, discountRate);
        this.mapInterestedParties(mappedProposal);
        this.mapReferrals(mappedProposal);
        return mappedProposal;
    }

    mapInterestedParties(proposal) {
        const uiConfig = proposal.originator?.behaviours?.find((b) => b._id === proposal.behaviour)?.uiConfig;
        const interestedPartyTypes = uiConfig?.interestedPartyTypes;

        proposal.clientProposalInterestedParties = proposal.interestedParties
            .filter((ip: ProposalInterestedParty) => ip.notedOn != InterestedPartyNotedOn.asset)
            .map(
                (ip: ProposalInterestedParty) =>
                    ip.name +
                    ' : ' +
                    (interestedPartyTypes?.find((ipType) => ipType.type === ip.type)?.display ??
                        interestedPartyTypeMapping.get(ip.type))
            );
    }

    mapClientQuotes(proposal: any, uiConfig?: UiConfig) {
        if (proposal.clientQuote) {
            const aircraftQuotes = (proposal?.clientQuote?.assetQuotes as AssetQuote[])
                .filter((q) => q.assetType === AssetType.aircraft)
                .map((assetQuote) => {
                    const assetProposalId =
                        typeof assetQuote.assetProposal === 'string'
                            ? assetQuote.assetProposal
                            : assetQuote.assetProposal._id;
                    const aircraft = ((proposal.aircraft as AircraftProposal[]) || []).find((aircraft) => {
                        return aircraft._id === assetProposalId;
                    });
                    return { ...aircraft, ...assetQuote };
                });
            proposal.clientQuote.aircraftQuotes = aircraftQuotes;
            const uavQuotes = (proposal?.clientQuote?.assetQuotes as AssetQuote[])
                .filter((q) => q.assetType === AssetType.uav)
                .map((assetQuote) => {
                    const assetProposalId =
                        typeof assetQuote.assetProposal === 'string'
                            ? assetQuote.assetProposal
                            : assetQuote.assetProposal._id;
                    const uav = ((proposal.uavs as AircraftProposal[]) || []).find((uav) => {
                        return uav._id === assetProposalId;
                    });
                    return { ...uav, ...assetQuote };
                });
            proposal.clientQuote.uavQuotes = uavQuotes;
            const payloadQuotes = (proposal?.clientQuote?.assetQuotes as AssetQuote[])
                .filter((q) => q.assetType === AssetType.uavPayload)
                .map((assetQuote) => {
                    const assetProposalId =
                        typeof assetQuote.assetProposal === 'string'
                            ? assetQuote.assetProposal
                            : assetQuote.assetProposal._id;
                    const payload = ((proposal.payloads as AircraftProposal[]) || []).find(
                        (payload) => payload._id === assetProposalId
                    );
                    let payloadType = payload.payloadType;
                    if (uiConfig?.payloadTypes) {
                        payloadType =
                            uiConfig.payloadTypes?.find((u) => payload.payloadType === u.type)?.display ?? payloadType;
                    }
                    return { ...payload, payloadType, ...assetQuote };
                });
            proposal.clientQuote.payloadQuotes = payloadQuotes;
            const equipmentQuotes = (proposal?.clientQuote?.assetQuotes as AssetQuote[])
                .filter((q) => q.assetType === AssetType.uavEquipment)
                .map((assetQuote) => {
                    const assetProposalId =
                        typeof assetQuote.assetProposal === 'string'
                            ? assetQuote.assetProposal
                            : assetQuote.assetProposal._id;
                    const equipment = ((proposal.equipment as AircraftProposal[]) || []).find(
                        (equipment) => equipment._id === assetProposalId
                    );
                    let equipmentType: string = equipment.equipmentType;
                    if (uiConfig?.payloadTypes) {
                        equipmentType =
                            uiConfig?.equipmentTypes?.find((u) => equipment.equipmentType === u.type)?.display ??
                            equipment.equipmentType;
                    }

                    return { ...equipment, equipmentType, ...assetQuote };
                });
            proposal.clientQuote.equipmentQuotes = equipmentQuotes;

            proposal.clientQuote.assetQuotes = [...aircraftQuotes, ...uavQuotes, ...equipmentQuotes, ...payloadQuotes];
        }
    }

    mapPolicyEndorsements(endorsements: Endorsement[], proposal: ClientProposal, discountRate: number) {
        const { transactionType } = proposal;

        let clientProposalEndorsements = proposal.endorsements;
        if ([TransactionType.amendment, TransactionType.cancellation].includes(transactionType)) {
            clientProposalEndorsements = proposal.endorsements.filter((e) => !!e.amendType);
        }

        // FIXME: This maps proposal and quote information onto the Endorsement configuration. At this point, all
        // necessary information should be on the proposal or quote itself and the Endorsement configuration should be
        // unnecessary. This should be refactored to just use the proposal data directly.
        proposal['policyEndorsements'] =
            endorsements
                .filter(
                    (e) =>
                        e.appliesTo === EndorsementAppliesTo.policy &&
                        e.category &&
                        ![EndorsementCategory.standard, EndorsementCategory.bundled].includes(
                            e.category as EndorsementCategory
                        ) &&
                        !!clientProposalEndorsements.find((cpe) => cpe.type === e.type || cpe.endorsement === e._id)
                )
                .map((e) => {
                    const proposedEndorsement = clientProposalEndorsements.find(
                        (cpe) => cpe.type === e.type || cpe.endorsement === e._id
                    ) as ProposalEndorsement;

                    return {
                        ...e,
                        amendType: proposedEndorsement?.amendType,
                        proposedEndorsement,
                        liabilityLimits: proposal.liabilityLimits,
                        clientQuote: proposal.clientQuote,
                        discountRate: discountRate,
                        underwritingConfigVersions:
                            proposal.clientQuote?.endorsements?.find((_e) => _e.type === e.type)
                                ?.underwritingConfigVersions ?? [],
                    };
                }) ?? [];
    }

    mapReferrals(proposal: any) {
        const {
            clientQuote: { assetQuotes },
        } = proposal;

        this.referrals = {
            assetReferrals:
                (assetQuotes as AssetQuote[])
                    .filter((aq) => aq.referrals?.length)
                    .map((aq) => {
                        let assetProposals = this.getAssetProposal(aq, proposal);
                        const assetProposalId =
                            typeof aq.assetProposal === 'string' ? aq.assetProposal : aq.assetProposal._id;

                        const assetProposal = (assetProposals as any[]).find((ap): ap is AssetProposal =>
                            typeof ap === 'string' ? undefined : ap._id === assetProposalId
                        );

                        if (!assetProposal) {
                            console.error(
                                `Expected to find an asset proposal but there was none. Proposal: ${JSON.stringify(
                                    aq.assetProposal,
                                    null,
                                    4
                                )}`
                            );
                        }

                        let description = assetProposal?.serialNumber ?? assetProposal?.description ?? '';
                        if (assetProposal.type === AssetType.uav) {
                            description = `${assetProposal.serialNumber} - ${assetProposal.manufacturer} ${
                                assetProposal.model ?? assetProposal.uavModel
                            }`;
                        } else if (
                            ([AssetType.uavPayload, AssetType.uavEquipment] as AssetType[]).includes(assetProposal.type)
                        ) {
                            description = `${assetProposal.serialNumber} - ${assetProposal.description}`;
                        }

                        return {
                            description,
                            referralReasons:
                                aq.referrals
                                    ?.map((r) => {
                                        if (r.type === ReferralType.authority) {
                                            const reasons = r.reasonString.split(' - ').map((aa) => aa.split(' --- '));
                                            return reasons.map((r) => ({ reason: r[0], subReasons: [...r].slice(1) }));
                                        }
                                        return { reason: r.reasonString, subReasons: [] };
                                    })
                                    .flat(1) ?? [],
                        };
                    }) ?? [],
            policyReferrals:
                proposal.clientQuote.referrals?.map((r) => {
                    const policyReferral: PolicyReferral = {
                        description: referralTypeMapping.get(r.type) ?? r.type,
                        referralReason: r.reasonString,
                        detailedReasons: [],
                        additionalDetails: [],
                    };

                    if (r.type === ClientReferralType.hygiene && r.data?.hygiene?.length) {
                        if (typeof r.data.hygiene[0] === 'object') {
                            // remove this 'if' after HSSB work has been merged to `dev` branch
                            policyReferral.detailedReasons.push(
                                ...r.data.hygiene.map((h) => `Question: ${h.question} - Answer: ${h.answer}`)
                            );

                            //PP-3713 display Additional information
                            r.data.hygiene.forEach((h) => {
                                if (h.details?.length) {
                                    policyReferral.additionalDetails.push(`Additional information: ${h.details}`);
                                } else {
                                    policyReferral.additionalDetails.push('');
                                }
                            });
                        } else {
                            // remove this 'else' clause after HSSB work has been merged to `dev` branch
                            policyReferral.detailedReasons.push(r.data.hygiene.toString());
                        }
                    }

                    return policyReferral;
                }) ?? [],
        };

        if (proposal.clientQuote.referrals.some((r) => r.type === 'statusFlags')) {
            this.mapStatusFlags();
        }
    }

    async mapStatusFlags() {
        const statusFlagTypeTitles: { [F in StatusFlagType]: string } = {
            claims: 'Claim(s)',
            priorClaims: 'Prior Claim(s)',
            organisation: 'Broker Caution',
            premium: 'Premium Owing',
            fnol: 'Notice of Loss',
        };
        if (!this.clientPolicy) {
            console.error('Unable to map status flags without client policy');
        }
        this.statusFlags.loading = true;
        this.statusFlags.display = true;
        this.statusFlags.flagTypes = [];

        const flags = await this._sdk.idf.StatusFlags.getActive(this.behaviourId, this.clientPolicy._id);

        await Promise.all(
            flags.map(async (statusFlag) => {
                const {
                    amountDue,
                    amountIncurred,
                    amountIncurredEstimate,
                    assetId,
                    claimStatus,
                    details: additionalDetails,
                    expiryDate,
                    type,
                    referenceClaim,
                    referenceIncident,
                    referencePolicyExternal,
                    renewalDate,
                    organisation,
                    createdAt,
                } = statusFlag;

                let flagIdentifier: string;
                let details: string[] = [];

                if (referencePolicyExternal && type !== StatusFlagType.premium) {
                    details.push(`External Policy Reference: ${referencePolicyExternal}`);
                }
                if (renewalDate && expiryDate && type !== StatusFlagType.organisation) {
                    details.push(`Policy Period: ${formatDate(renewalDate)} - ${formatDate(expiryDate)}`);
                }

                switch (type) {
                    case StatusFlagType.claims:
                    case StatusFlagType.priorClaims:
                        flagIdentifier = `Claim Reference: ${referenceClaim}`;
                        if (assetId) {
                            const asset = [...(this.model.aircraft || []), ...(this.model.uavs || [])].find(
                                (asset) => asset.asset === assetId
                            );
                            if (asset) {
                                details.push(`Asset: ${asset.serialNumber || asset.registrationNumber}`);
                            }
                        }
                        if (typeof amountIncurred === 'number') {
                            details.push(`Amount Incurred: ${amountIncurred}`);
                        } else {
                            details.push(`Amount Incurred (Estimate): ${amountIncurredEstimate}`);
                        }
                        details.push(`Claim Status: ${claimStatus}`);
                        break;
                    case StatusFlagType.fnol:
                        flagIdentifier = `Notice of Loss Reference: ${referenceIncident}`;
                        break;
                    case StatusFlagType.organisation:
                        const org = await this._sdk.idf.Organisations.get({ organisationId: organisation }).then(
                            (orgs) => orgs[0]
                        );
                        if (org) {
                            flagIdentifier = `${org.name}`;
                        } else {
                            console.error('Organisation not found for ' + organisation);
                            return;
                        }
                        break;
                    case StatusFlagType.premium:
                        const amountDueStr = `Amount Due: ${amountDue}`;
                        if (referencePolicyExternal) {
                            flagIdentifier = `External Policy Reference: ${referencePolicyExternal}`;
                            details.push(amountDueStr);
                        } else {
                            flagIdentifier = amountDueStr;
                        }
                        break;
                }

                if (createdAt) {
                    details.push(`Status Flag Received: ${formatDate(createdAt)}`);
                }

                const display: StatusFlagDisplay = {
                    identifier: flagIdentifier,
                    details,
                    additionalDetails,
                };

                const flagTypes = this.statusFlags.flagTypes.find(({ type: flagType }) => type === flagType);
                if (!flagTypes) {
                    this.statusFlags.flagTypes.push({ type, title: statusFlagTypeTitles[type], flags: [display] });
                } else {
                    flagTypes.flags.push(display);
                }
                return;
            })
        );

        this.statusFlags.loading = false;
    }

    getAssetProposal(assetQuote: AssetQuote, proposal: ClientProposal): string[] | AssetProposal[] {
        switch (assetQuote.assetType) {
            case AssetType.aircraft: {
                return proposal.aircraft;
            }
            case AssetType.uav: {
                return proposal.uavs;
            }
            case AssetType.uavEquipment: {
                return proposal.equipment;
            }
            case AssetType.uavPayload: {
                return proposal.payloads;
            }
            default: {
                return [];
            }
        }
    }

    openDeclineModal() {
        this.action = UnderwritingActions.decline;
        const modal = this.fields.find((fg) => fg.key === 'declineModal');
        modal?.templateOptions?.listener.next('openFromEvent');
    }

    decline() {
        this.processing = true;
        let reason = [this.model.declineModal.declineReasonSelect, this.model.declineModal.declineReasonInput]
            .filter((r) => r)
            .join(',');

        this.quoteService.declineQuote(this.proposalId, this.behaviourId, reason).subscribe(
            (data) => {
                this.notificationService.success('Declined quote successfully!');
                this.model.clientQuote.status = QuoteStatus.declined;
                this.processing = false;
                this.showUnderwritingButton = false;
                this.changeDetectorRef.detectChanges();
            },
            (error) => {
                this.notificationService.error(`Declined quote error, ${JSON.stringify(error)}`);
                this.processing = false;
            }
        );
    }

    openAcceptModal(): void {
        if (!this.isValidToAccept) {
            return;
        }
        this.action = UnderwritingActions.accept;
        this.confirmDialog.open('Please confirm you want to accept this referral!');
    }

    async accept() {
        if (!this.isValidToAccept) {
            return;
        }

        this.processing = true;

        // this.model.clientQuote.additionalNotes = [this.model.notes].filter((n) => n);
        this.model.clientQuote.additionalNotes = this.notes;
        delete this.model.clientQuote.quoteNumber;

        const clientQuote = this.cleanClientQuote(this.model.clientQuote);
        const behaviour = this.model.behaviour;

        clientQuote.assetQuotes.forEach((aq) => {
            aq.priceYearTotal = aq.priceYearArray
                ?.map((p) => p.liability ?? p.hull ?? p.standard ?? 0)
                .reduce((a, b) => a + b);
        });

        this.quoteService
            .acceptQuote(clientQuote._id, {
                clientQuote,
                behaviour,
            })
            .subscribe({
                next: () => {
                    this.notificationService.success('Accepted quote successfully!');
                    this.processing = false;
                    this.model.clientQuote.status = QuoteStatus.accepted;
                    this.showUnderwritingButton = false;
                    this.changeDetectorRef.detectChanges();
                },
                error: (error) => {
                    this.notificationService.error(`Accept quote error, ${JSON.stringify(error)}`);
                    this.processing = false;
                },
            });
    }

    private checkShowUnderwritingButton = () => {
        this.showUnderwritingButton = [
            QuoteStatus.dirty,
            QuoteStatus.filthy,
            QuoteStatus.coverageHeldDirty,
            QuoteStatus.coverageHeldFilthy,
        ].includes(this.model?.clientQuote?.status);
    };

    onAssetQuoteDataChange(assetModel: AssetModel) {
        const assetType = assetModel.assetType;
        const assetQuote = this.model.clientQuote.assetQuotes.find((aq) => aq._id === assetModel._id) as AssetQuote;
        const asset = this.model.clientQuote[`${assetType}Quotes`].find((aq) => aq._id === assetModel._id);

        if (assetQuote) {
            assetQuote.priceYearArray = assetModel.priceYearArray ?? [];
            assetQuote.priceYearAdjusted = assetModel.priceYearAdjusted ?? [];
            assetQuote.rateYear = assetModel.rateYear ?? null;
            assetQuote.priceYearHull = assetModel.priceYearHull ?? null;
            assetQuote.priceYearCsl = assetModel.priceYearCsl ?? null;
            assetQuote.priceYearTpl = assetModel.priceYearTpl ?? null;
            assetQuote.priceYearTotal = assetModel.priceYearArray
                ?.map((p) => p.liability ?? p.hull ?? p.standard ?? 0)
                .reduce((a, b) => a + b);
        }

        if (asset) {
            asset.priceYearArray = assetModel.priceYearArray;
            asset.priceYearAdjusted = assetModel.priceYearAdjusted;
            asset.rateYear = assetModel.rateYear;
            asset.priceYearHull = assetModel.priceYearHull;
            asset.priceYearCsl = assetModel.priceYearCsl;
            asset.priceYearTpl = assetModel.priceYearTpl;

            asset.pricesChanged = assetModel.pricesChanged;
        }
        this.model.clientQuote[`${assetType}Quotes`] = [...this.model.clientQuote[`${assetType}Quotes`]];

        const agGridField = this.getAssetGridField(assetType);
        const gridApi = agGridField?.templateOptions?.gridOptions?.api;
        gridApi.refreshCells({ force: true });
        this.updateAssetTypeTotalPremium(gridApi, assetType);
        agGridField?.formControl?.setValue(Object.assign([...this.model.clientQuote[`${assetType}Quotes`]]));
    }

    private getAssetGridField(assetType: AssetType) {
        const agGridField = this.formlyForm?.fields.find(
            (f) => f.type === 'ag-grid' && f.key === `clientQuote.${assetType}Quotes`
        );
        return agGridField;
    }

    private updateAssetTypeTotalPremium(gridApi: GridApi, assetType: AssetType) {
        const totalPremium = _.sum(
            (this.model.clientQuote.assetQuotes as AssetQuote[])
                .filter((aq) => aq.assetType === assetType && this.validateAssetQuote(aq as unknown as AssetModel))
                .map((aq) => _.sum(aq.priceYearAdjusted.map((pya) => pya.hull || pya.liability || pya.standard)))
        );

        let totalRow = {
            totalPremium: _.round(totalPremium * (1 - this.discountRate), 2),
        };
        const totalCol = this.model.transactionType === TransactionType.newBusiness ? 'serialNumber' : 'amendType';
        let assetTypes: string;
        if (assetType === AssetType.uav) {
            assetTypes = 'Drone';
        } else if (assetType === AssetType.uavEquipment) {
            assetTypes = 'Ground Equipment';
        } else if (assetType === AssetType.uavPayload) {
            assetTypes = 'Payload';
        } else if (assetType === AssetType.aircraft) {
            assetTypes = 'Aircraft';
        } else {
            assetTypes = ' ';
        }
        totalRow[totalCol] = `Total ${assetTypes} Premium`;

        gridApi?.setPinnedBottomRowData(totalPremium ? [totalRow] : []);
    }

    private editAsset(event: any) {
        const assetProposal: AssetProposal & AssetQuote = event.data;
        const clientProposal = this.model;
        const clientQuote: ClientQuote = clientProposal.clientQuote;

        const insurerAssetTemplate = this.insurerAssetTemplates?.find(
            (iat) => iat.assetTemplate === assetProposal.assetTemplate
        );
        const assetTemplate = this.assetTemplates.find((at) => at._id === assetProposal.assetTemplate);
        const formConfig = this.formConfigs?.find((fc) => fc.abstract === assetTemplate?.abstract);

        const endorsements: AssetModel['endorsements'] = cloneDeep(
            getAssetEndorsements(
                this.endorsements,
                (assetProposal?.endorsements || []).map((e) => e._id),
                (assetProposal?.endorsements || []).map((e) => e.type),
                assetProposal.uses ?? [],
                insurerAssetTemplate
            )
        );

        endorsements.forEach((e) => {
            // add Version to proposed endorsements from clientQuote
            e.underwritingConfigVersions =
                clientQuote.endorsements?.find((_e) => _e.type === e.type)?.underwritingConfigVersions ??
                (assetProposal.endorsements as QuoteEndorsement[])?.find((_e) => _e.type === e.type)
                    ?.underwritingConfigVersions ??
                [];

            if (e.premiumRates?.length > 0) {
                const assetProposalEndorsement = assetProposal.endorsements.find(
                    (endorsement: ProposalEndorsement) => endorsement.type === e.type
                );
                if (assetProposalEndorsement) {
                    e.limit = assetProposalEndorsement.limit;
                    const premiumRate = e.premiumRates.find((pr) => pr.value === assetProposalEndorsement.limit);
                    if (premiumRate) {
                        e.premium = premiumRate?.price;
                    }
                }
            }
        });

        const assetPolicy = this.clientPolicy?.assetPolicies?.find((ap) => ap._id === assetProposal.assetPolicy);
        const existingEndorsements: AssetModel['endorsements'] = cloneDeep(
            getAssetEndorsements(
                this.endorsements,
                [],
                (assetPolicy?.endorsements || []).map((ce) => ce.type),
                assetPolicy?.useTypes ?? [],
                insurerAssetTemplate
            )
        );

        // add Version to existing endorsements from clientPolicy
        existingEndorsements.forEach((e) => {
            e.underwritingConfigVersions =
                assetPolicy.endorsements.find((_e) => _e.type === e.type)?.underwritingConfigVersions ?? [];
        });

        const existingAsset = this.existingAssets?.find((a) => a._id === assetPolicy?.appliesTo.asset);
        const existingPilots = this.existingPilots
            ?.filter((p) => (assetPolicy?.pilots ?? []).includes(p._id))
            .map((p) => ({
                ...p,
                totalAircraftFlightTime: p.flightTimes?.find((ft) => ft.assetTemplate === assetProposal.assetTemplate)
                    ?.flightTime,
                totalAircraftFlightTimeLast90Days: p.flightTimesLast90Days?.find(
                    (ft) => ft.assetTemplate === assetProposal.assetTemplate
                )?.flightTime,
                totalAircraftFlightTimeLast12Months: p.flightTimesLast12Months?.find(
                    (ft) => ft.assetTemplate === assetProposal.assetTemplate
                )?.flightTime,
            }));

        const modalData = {
            ...assetProposal,
            transactionType: clientProposal.transactionType,
            currency: clientProposal.clientQuote.currency,
            locale: clientProposal.locale,
            interestedParties: clientProposal.interestedParties
                ?.filter(
                    (ip) =>
                        ip.notedOn === InterestedPartyNotedOn.asset &&
                        (assetProposal.interestedParties ?? []).includes(ip.name)
                )
                .map((ip) => ({ name: ip.name, type: ip.type })),
            pilots: clientProposal.pilots
                ?.filter((p) => (assetProposal.pilotProposals ?? []).includes(p._id))
                .map((p) => ({
                    ...p,
                    totalAircraftFlightTime: p.flightTimes?.find(
                        (ft) => ft.assetTemplate === assetProposal.assetTemplate
                    )?.flightTime,
                    totalAircraftFlightTimeLast90Days: p.flightTimesLast90Days?.find(
                        (ft) => ft.assetTemplate === assetProposal.assetTemplate
                    )?.flightTime,
                    totalAircraftFlightTimeLast12Months: p.flightTimesLast12Months?.find(
                        (ft) => ft.assetTemplate === assetProposal.assetTemplate
                    )?.flightTime,
                })),
            endorsements,
            existingAsset,
            existingEndorsements,
            existingPilots,
            insurerAssetTemplate,
            assetPolicy,
            formConfig,
        };

        this.editAssetModal.open(modalData);
    }

    validateAssetQuote = (assetQuote: AssetModel) => {
        const insurerAssetTemplate = this.insurerAssetTemplates?.find(
            (iat) => iat.assetTemplate === assetQuote.assetTemplate
        );

        const errors = validateAssetQuote(
            assetQuote as unknown as AssetQuote,
            assetQuote as AssetProposal,
            getAssetEndorsements(
                this.endorsements,
                (assetQuote.endorsements || []).map((e) => e._id),
                (assetQuote.endorsements || []).map((e) => e.type),
                assetQuote.useTypes ?? [],
                insurerAssetTemplate
            ),
            {
                endorsements: assetQuote.endorsements,
                transactionType: this.model.transactionType,
            } as unknown as ClientProposal, // FIXME: validation function uses endorsements from clientProposal
            this.clientPolicy?.assetPolicies?.find((ap) => ap._id === assetQuote.assetPolicy)
        );

        if (!errors.length) {
            assetQuote.missingPremiums = {};
            return true;
        }

        const missingPremiums = {};
        errors.forEach((e) => {
            const priceType = e.price ?? 'endorsement';

            missingPremiums[priceType] = true;
        });
        assetQuote.missingPremiums = missingPremiums;

        return false;
    };

    cleanClientQuote(clientQuote: any) {
        let cleanQuote = _.cloneDeep(clientQuote);
        cleanQuote.assetQuotes.forEach((aq) => {
            aq.priceYearAdjusted = filterZeroPrices(aq.priceYearAdjusted);
        });
        cleanQuote.priceYearAdjusted = filterZeroPrices(cleanQuote.priceYearAdjusted);

        return cleanQuote;
    }

    currencyValueFormatter = (params: ValueFormatterParams): string =>
        (params.value ?? '') !== '' ? formatCurrencyAmountByFormatter(params.value, this.currencyFormatter) : '';

    deleteNote(index: number) {
        this.notes.splice(index, 1);
    }

    editNote() {
        if (this.EditSave == 'Edit') {
            this.isNoteDisabled = false;
            this.EditSave = 'Save';
        } else {
            this.isNoteDisabled = true;
            this.EditSave = 'Edit';
        }
    }

    private _mapEndorsements() {
        const { configurationDocuments, endorsements: configEndorsements } = this.insurerUnderwritingConfig;

        for (const endorsement of configEndorsements ?? []) {
            let configurationDocument: ConfigurationDocuments;
            if ((endorsement?.underwritingConfigVersions?.length ?? 0) > 0) {
                configurationDocument = configurationDocuments.find((doc) =>
                    isEqual(doc.underwritingConfigVersions, endorsement.underwritingConfigVersions)
                );
            } else {
                configurationDocument = this.configDocsWithoutVersions;
            }

            if (!configurationDocument) {
                throw new Error(
                    [
                        `Unable to match underwritingConfigVersions of endorsement ${JSON.stringify(endorsement)}`,
                        `with the version in configurationDocument ${JSON.stringify(
                            configurationDocuments.map((doc) => doc.underwritingConfigVersions)
                        )}`,
                    ].join(' ')
                );
            }

            const { insurerPrice } = configurationDocument;
            const _endorsement = insurerPrice.endorsements.find((_e) => _e.type === endorsement.type);

            if (!_endorsement) {
                throw new Error(`Endorsement type: ${endorsement.type} not found in insurer price document.`);
            }

            const index = this.endorsements.findIndex((ee) => ee.type === _endorsement.type);
            if (index >= 0) {
                this.endorsements[index] = _endorsement;
            } else {
                this.endorsements.push(_endorsement);
            }
        }
    }

    private _mapInsurerAssetTemplates() {
        const { assets, configurationDocuments } = this.insurerUnderwritingConfig;
        for (const asset of assets ?? []) {
            let configurationDocument: ConfigurationDocuments;
            if ((asset?.underwritingConfigVersions?.length ?? 0) > 0) {
                configurationDocument = configurationDocuments.find((doc) =>
                    isEqual(doc.underwritingConfigVersions, asset.underwritingConfigVersions)
                );
            } else {
                configurationDocument = this.configDocsWithoutVersions;
            }

            if (!configurationDocument) {
                throw new Error(
                    [
                        `Unable to match underwritingConfigVersions of Asset ${JSON.stringify(asset)}`,
                        `with the version in configurationDocument ${JSON.stringify(
                            configurationDocuments.map((doc) => doc.underwritingConfigVersions)
                        )}`,
                    ].join(' ')
                );
            }

            const { insurerAssetTemplates } = configurationDocument;

            const insurerAssetTemplate = insurerAssetTemplates.find((_i) => _i.assetTemplate === asset.assetTemplate);

            if (!insurerAssetTemplate) {
                throw new Error(`No InsurerAssetTemplate found for asset with assetTemplate: ${asset.assetTemplate}`);
            }

            const index = this.insurerAssetTemplates.findIndex(
                (iat) => iat.assetTemplate === insurerAssetTemplate.assetTemplate
            );
            if (index >= 0) {
                this.insurerAssetTemplates[index] = insurerAssetTemplate;
            } else {
                this.insurerAssetTemplates.push(insurerAssetTemplate);
            }
        }
    }
}
